angular.module('comcast.common.notification').service('NotificationService', ['$mdToast', function ($mdToast) {
  'use strict';

  var vm = this;

  vm.showNotificationToast = function (message, error) {
    $mdToast.show({
      hideDelay: 10000,
      position: 'top right',
      controller: 'ToastCtrl',
      controllerAs: 'vm',
      template: require('./notification-template.html'),
      multiple: false,
      locals: {
        content: message,
        errorText: error
      }
    });
  };

  vm.hideNotificationToast = function () {
    $mdToast.hide();
  };
}]).controller('ToastCtrl', ["$mdToast", "$mdDialog", "content", "errorText", function ($mdToast, $mdDialog, content, errorText) {
  /* PRIVATE : DATA */
  //Declare all private variables here

  /*
   * This is the `vm` object. It is a direct reference to the controller
   * which acts as our 'view-model' in angular. It also limits our need
   * to be accessing $scope directly. */

  /*jshint validthis:true*/
  var vm = this;
  /* BINDABLE : DATA */

  vm.toastContent = content;
  vm.moreInfoContent = errorText;
  /* BINDABLE : METHODS*/

  vm.closeToast = closeToast;
  vm.openMoreInfo = openMoreInfo;
  /* EVENTS */
  //Register any event listeners

  /* INITIALIZATION */
  //Call controller initialization method here

  _init();
  /* IMPLEMENTATION : BINDABLE */


  function closeToast() {
    $mdToast.hide();
  }

  function openMoreInfo(e) {
    $mdDialog.show($mdDialog.alert().title('More Information').htmlContent(vm.moreInfoContent).ariaLabel('More info').ok('Okay').targetEvent(e).multiple(true));
  }
  /* IMPLEMENTATION : PRIVATE */
  // All private methods should start with '_' in order to distinguish them

  /**
   * Initialization for this controller.
   */


  function _init() {//TODO: Initialize this controller
  }
}]);